<template>
    <div>
        <div class="zhidingsearch">
            <el-form>
                <div class="litop">
                    <el-form ref="formModal">
                        <el-row>
                            <el-col :span="5">
                                <div class="grid-content bg-purple">
                                    <el-form-item label="标题">
                                        <el-input v-model="formModal.name" placeholder="请输入标题名称" clearable
                                            @keyup.enter.native="standard()"></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div>
                                    <el-form-item label="标签">
                                        <el-select v-model="formModal.tig" clearable placeholder="请选择">
                                            <el-option v-for="item in labelOptions" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="grid-content bg-purple-light">
                                    <el-form-item label="分类">
                                        <el-select v-model="formModal.type" placeholder="分类" clearable>
                                            <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="grid-content bg-purple-light">
                                    <el-form-item label="状态">
                                        <el-select v-model="formModal.status" placeholder="状态" clearable>
                                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="5">
                                <div class="grid-content bg-purple-light">
                                    <el-form-item label="是否发布">
                                        <el-select v-model="formModal.publish" placeholder="发布" clearable>
                                            <el-option v-for="item in publishOptions" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div class="grid-content bg-purple">
                                    <el-button type="primary" @click="standard()">查询</el-button>
                                    <el-button type="info" @click="qingkong('formModal')">清空</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-form>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'formhead',
    props: {
        msg: String,
        // titleName: String,
        // labelVal: String,
        // zhidingType: String,
        // searchStandard: {
        //     type: Function,
        //     default: function () { }
        // },
        // qingkong: {
        //     type: Function,
        //     default: function () { }
        // }
    },
    data() {
        return {
            labelOptions: [
                {
                    label: '最新新闻',
                    value: 1,
                },
                {
                    label: '科技动态',
                    value: 2,
                },
                {
                    label: '最新政策',
                    value: 3,
                },
                {
                    label: '国内新闻',
                    value: 4,
                },
                {
                    label: '国际科技',
                    value: 5,
                },
                {
                    label: '国内科技',
                    value: 6,
                }
            ],
            classifyOptions: [
                {
                    label: '地方标准发布',
                    value: 11,
                },
                // {
                //     label: '企业标准发布（公告）',
                //     value: 12,
                // },
                // {
                //     label: '企业标准发布（公示）',
                //     value: 16,
                // },
                // {
                //     label: '企业标准发布（文件）',
                //     value: 18,
                // },
                {
                    label: '地方标准发布(意见征求)',
                    value: 15,
                },
            ],
            statusOptions: [
                {
                    label: '未校验',
                    value: 1,
                },
                {
                    label: '已审核',
                    value: 2,
                },
            ],
            publishOptions: [
                {
                    label: '发布',
                    value: '1',
                },
                {
                    label: '未发布',
                    value: '0',
                },
            ],
            formModal: {
                name: "",
                tig: "",
                type: "",
                status: "",
                publish: ""
            }
        }
    },
    methods: {
        standard() {
           this.$emit('fuzzy-search', this.formModal)
        },
        qingkong() {
            this.formModal ={
                name: "",
                tig: "",
                type: "",
                status: "",
                publish: ""
            }
            this.$emit('clear-query')
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zhidingsearch {
    background-color: #ffffff;
    padding: 16px 15px;
    margin: 20px 0 10px 0;
}

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
}
</style>
  