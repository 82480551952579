<template>
    <div class="addvice_content">
        <el-dialog :visible.sync="adviceDialogVisible" title="新增地方标准发布(意见征求)" :close-on-click-modal="false"
            @close="cancleEdit()" custom-class="stand_dialog" :getRow="getRow">
            <el-form :model="adviceForm" ref="adviceForm" class="adviceForm">
                <div class="title">
                    <div class="text">江苏省工程建设地方标准</div>
                    <div>
                        <el-form-item prop="subject" :rules="[{ required: true, message: '请选择标准名称', trigger: 'change' }]">
                            <el-select v-model="adviceForm.subject" filterable clearable>
                                <el-option v-for="item in standardList" :key="item.id" :label="item.proName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="text">公开征求意见</div>
                </div>
                <div class="article">
                    <span>江苏省工程建设地方标准</span>
                    <el-select v-model="adviceForm.subject" filterable clearable>
                        <el-option v-for="item in standardList" :key="item.id" :label="item.proName" :value="item.id">
                        </el-option>
                    </el-select>
                    <span>已完成征求意见稿，现面向社会公开征求意见。任何组织和个人如有修改意见或建议，</span>
                </div>
                <div class="show_text"><span>请填写标准征求意见表，于</span>
                    <el-form-item prop="wxdate" :rules="[{ required: true, message: '请选择日期', trigger: 'blur' }]">
                        <el-date-picker v-model="adviceForm.wxdate" type="date" value-format="yyyy年MM月dd"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item><span>前反馈至江苏省工程建设标准站。</span>
                </div>
                <div class="form_line">
                    <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '请选择类型', trigger: 'change' }]">
                        <el-select v-model="adviceForm.type" clearable>
                            <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系人" prop="name" :rules="[{ required: true, message: '请输入联系人', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="address"
                        :rules="[{ required: true, message: '请输入地址', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.address"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone"
                        :rules="[{ required: true, message: '请输入联系电话', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="邮政编码" prop="zipcode"
                        :rules="[{ required: true, message: '请输入邮政编码', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.zipcode"></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮箱" prop="email"
                        :rules="[{ required: true, message: '请输入电子邮箱', trigger: 'blur' }]">
                        <el-input v-model="adviceForm.email"></el-input>
                    </el-form-item>
                    <el-form-item label="附件" prop="type" :rules="[{ required: true, message: '请上传附件', trigger: 'change' }]">
                        <upload-file ref="uploadWord" :fileType="fileType" @standard-data="getArtFile"></upload-file>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancleEdit()">取 消</el-button>
                <el-button type="primary" icon="el-icon-position" @click="submitForm('adviceForm')">新 增</el-button>
            </div>
        </el-dialog>
    </div>
</template>
 
<script>
import uploadFile from "../components/uploadFile.vue"
import { standardSelect, standardModelAdd } from "../../../../../api/project"
export default {
    components: { uploadFile },
    data() {
        return {
            adviceDialogVisible: false,
            adviceForm: {
                subject: "",
                wxdate: "",
                name: "吴晓春",
                address: "南京市鼓楼区草场门大街88号江苏建设大厦8楼811室",
                phone: "025-51868172",
                zipcode: "210036",
                email: "jsgcjsbz@163.com",
                // url:""
                fileList: []
            },
            fileType: "fileWord",
            classifyOptions: [
                {
                    label: '地方标准发布',
                    value: 11,
                },
                {
                    label: '地方标准发布(意见征求)',
                    value: 15,
                },
            ],
            standardList: [],
            fileObj: null,
        }
    },
    props: {
        getRow: {
            type: Object
        }
    },
    created() {
    },
    mounted() {
        // this.queryStandards()
    },
    methods: {
        queryStandards() {
            // debugger
            standardSelect({ token: this.$store.state.token }).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                this.standardList = rec.data
            })
        },
        cancleEdit() {
            this.adviceDialogVisible = false
            this.$refs.adviceForm.resetFields();
        },
        submitForm(formName) {
            // console.log('打印表单内容', this.adviceForm)
            // console.log(this.adviceForm.subject)
            let subjectName = ''
            let subject = ''
            if (this.adviceForm.subject != '') {
                subjectName = this.standardList.filter((item) => item.id == this.adviceForm.subject);
                subject = subjectName[0].proName
                // console.log('打印名字', subjectName)
            }

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let fileList = []
                    if (this.fileObj != null) {
                        this.adviceForm.fileList = this.fileObj.fileList
                        fileList = this.adviceForm.fileList
                    }

                    let content = `<p style="text-align:justify;font-size:20px;color:#333;">江苏省工程建设地方标准${subject}已完成征求意见稿，现面向社会公开征求意见。任何组织和个人如有修改意见或建议，请填写标准征求意见表，于${this.adviceForm.wxdate}前反馈至江苏省工程建设标准站。</p>`
                        + `&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">联系人：${this.adviceForm.name}</div>&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">地址：${this.adviceForm.address}</div>`
                        + `&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">联系电话：${this.adviceForm.phone}</div>&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">邮政编码：${this.adviceForm.zipcode}</div>`
                        + `&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">邮箱：${this.adviceForm.email}</div>&nbsp;<div style="font-family: 方正仿宋_GBK;font-size:16px;">附件：<div style="font-family: 方正仿宋_GBK;font-size:16px;">${fileList.map((item, index) => { return `<div><a style="color: #333;text-decoration: none;" href=${item.url}>${index + 1}. ${item.name}</a></div>` })}</div></div>`
                    // console.log(content, '文本内容最新')

                    let params = {
                        subject: subject,
                        content: content,
                        token: this.$store.state.token
                    }
                    console.log(params, '打印入参99000')
                    //新增
                    standardModelAdd(params).then(rec => {
                        // debugger
                        console.log('返回的结果', rec)
                        if (rec.code == 200) {
                            this.$message({
                                type: "success",
                                message: rec.message
                            })
                            this.adviceDialogVisible = false
                            this.$parent.getPublishData() //回调查询接口
                        }
                    })
                    // console.log(params, '打印提交的表单数据')
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getArtFile(obj) {
            console.log('打印传过来的图片', obj)
            this.fileObj = obj
        }
    }

}

</script>
<style scoped>
/deep/ .stand_dialog {
    width: 1080px !important;
}

/deep/ .addvice_content .el-form-item__content {
    display: flex;
}

/deep/ .adviceForm .el-form-item__label {
    width: 100px;
}

/deep/ .adviceForm .el-select,
/deep/ .adviceForm .el-input {
    width: 400px;
}
</style>
<style lang="scss" scoped>
.addvice_content {
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #333;
        margin-bottom: 11px;

        .text {
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            padding: 0px 11px 16px 11px;
        }
    }

    .article {
        width: 100%;
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;
        padding-top: 4px;

        // line-height: 36px;
        .text {
            padding: 10px 11px 16px 0;
        }

        .stand_name {
            width: 200px;
        }

        span {
            padding-top: 8px;
        }
    }

    .show_text {
        display: flex;
        font-size: 14px;
        text-align: left;
        padding-bottom: 11px;

        span {
            padding-top: 8px;
        }
    }
}
</style>