<template>
    <div>
        <div>
            <div>
                <standFormHead :markcouter="markcouter" @fuzzy-search="fuzzySearch" @clear-query="clearQuery">
                </standFormHead>
            </div>
            <div class="double_add">
                <div class="plus_add"><el-button type="primary" icon="el-icon-plus" @click="addFrom('0')">新增</el-button>
                </div>
                <div class="plus_add suggest"><el-button type="primary" icon="el-icon-plus" v-show="newsType === '15'"
                        @click="addFrom('1')">新建公开征求意见</el-button>
                </div>
            </div>
            <div class="pitable">
                <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
                    :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                    <el-table-column label="ID" prop="id" align="center" width="100">
                    </el-table-column>
                    <el-table-column label="状态" prop="status" align="center" width="130px">
                        <template slot-scope="{ row }">
                            <div v-if="!row.status">
                                <el-tag type="info">未校验</el-tag>
                            </div>
                            <div v-else-if="row.status == 1"><el-tag type="warning">已校验未审核</el-tag></div>
                            <div v-else-if="row.status == 2"><el-tag type="success">已审核</el-tag></div>
                            <div v-if="!row.publish"><el-tag type="info">未发布</el-tag></div>
                            <div v-else-if="row.publish == 1"><el-tag type="success">已发布</el-tag></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="标题" prop="subject" align="center">
                        <template slot-scope="{ row }">
                            <span class="subtext" @click="infoEvent(row)">{{ row.subject }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建日期" prop="created_at" align="center" width="130px">
                        <template slot-scope="{ row }">
                            <span>{{
                                row.created_at | formatDate("yyyy-MM-dd")
                            }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="分类" prop="category_id" align="center" width="130px">
                        <template slot-scope="{ row }">
                            <span v-if="row.category_id == 1"> 科技动态 </span>
                            <span v-else-if="row.category_id == 2"> 工作动态 </span>
                            <span v-else-if="row.category_id == 4"> 绿建联盟 </span>
                            <span v-else-if="row.category_id == 5"> 专家访谈 </span>
                            <span v-else-if="row.category_id == 6"> 专家视角 </span>
                            <span v-else-if="row.category_id == 7"> 建筑能效标识 </span>
                            <span v-else-if="row.category_id == 8"> 前沿资讯 </span>
                            <span v-else-if="row.category_id == 9"> 联盟工作 </span>
                            <span v-else-if="row.category_id == 10"> 会员访谈 </span>
                            <span v-else-if="row.category_id == 11"> 地方标准发布 </span>
                            <span v-else-if="row.category_id == 12"> 企业标准发布 </span>
                            <span v-else-if="row.category_id == 13">
                                科研成果推广项目
                            </span>
                            <span v-else-if="row.category_id == 14"> 其它示范案例 </span>
                            <span v-else-if="row.category_id == 15">
                                地方标准发布(意见征求)
                            </span>
                            <span v-else-if="row.category_id == 16">
                                企业标准发布（公示）
                            </span>
                            <span v-else> 暂无公告 </span>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="预览" prop="" align="center" width="200px">
                        <template slot-scope="{ row }">
                            <div @click="jumpArticle(row)">
                                <qrCode :rowId="row.id"></qrCode>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="150px">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <el-button icon="el-icon-edit" type="text" @click="handleUpdate(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <el-button icon="el-icon-delete" type="text" @click="handleDelete(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                <el-button icon="el-icon-setting" type="text" @click="handleCheck(row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="撤回" placement="top">
                                <el-button icon="el-icon-refresh-right" type="text" @click="withDraw(row)"></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="pageTotal">
                <div class="block">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div>
            <el-dialog :visible.sync="editDialogVisible" :title="newsType === '11' ? '新增/修改政策通知' : '新增/修改资讯'"
                :close-on-click-modal="false" @close="cancleEdit()">
                <el-form :model="editForm" ref="editForm">
                    <div class="form_line">
                        <el-form-item label="标题" prop="subject"
                            :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                            <el-input v-model="editForm.subject"></el-input>
                        </el-form-item>
                        <el-form-item label="小标题" prop="short_subject"
                            :rules="[{ required: true, message: '请输入小标题', trigger: 'blur' }]">
                            <el-input placeholder="请输入小标题" v-model="editForm.short_subject"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="分类" 
                            :rules="[{ required: false, message: '请选择分类', trigger: 'change' }]">
                            <el-input placeholder="请输入小标题" v-model="typeName" disabled></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="新闻标签" prop="tig">
                            <el-select v-model="editForm.tig" filterable placeholder="请选择新闻标签">
                                <el-option v-for="item in contentTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="日期" prop="wxdate"
                            :rules="[{ required: true, message: '请选择日期', trigger: 'change' }]">
                            <el-date-picker v-model="editForm.wxdate" type="date" placeholder="选择日期"
                                value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <!-- <el-form-item label="日期" prop="wxdate"
                            :rules="[{ required: true, message: '请选择日期', trigger: 'change' }]">
                            <el-date-picker v-model="editForm.wxdate" type="date" placeholder="选择日期"
                                value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item> -->
                        <!-- <el-form-item label="微信链接" prop="url">
                            <el-input placeholder="请输入微信链接" v-model="editForm.url"></el-input>
                        </el-form-item> -->
                    </div>
                    <div class="form_line">
                        <!-- <el-form-item label="审核人" prop="check">
                            <el-input placeholder="请输入审核人" v-model="editForm.check"></el-input>
                        </el-form-item>
                        <el-form-item label="企业" prop="organization_id">
                            <el-select v-model="editForm.organization_id" filterable placeholder="请选择企业">
                                <el-option v-for="item in organizationOptions" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                    </div>
                    <div class="form_line">
                        <!-- <el-form-item label="部门" prop="bumen">
                            <el-select v-model="editForm.bumen" filterable placeholder="请选择部门">
                                <el-option v-for="item in deptOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="是否置顶" prop="stick">
                            <el-radio-group v-model="editForm.stick">
                                <el-radio label="1">置顶</el-radio>
                                <el-radio label="0">不置顶</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="封面">
                            <upload-file ref="uploadimg" :fileType="fileType" @reserve-data="getFileData"></upload-file>
                        </el-form-item>
                        <el-form-item label="视频">
                            <upload-file @reserve-data="getFileData"></upload-file>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item prop="content" :rules="[{ required: true, message: '请输入内容', trigger: 'change' }]">
                            <richTextEdit ref="richTextRef" :content="editForm.content" @content-change="contentChange">
                            </richTextEdit>
                        </el-form-item>
                        <el-checkbox v-model="editForm.sheng" class="agree">本文由江苏省工程建设企业技术标准服务网发布，欢迎转载，注明出处</el-checkbox>
                    </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancleEdit()">取 消</el-button>
                    <el-button type="primary" icon="el-icon-paperclip" @click="saveEdit('editForm')" v-if="isAdd">暂
                        存</el-button>
                    <el-button type="success" icon="el-icon-position" @click="submitEdit('editForm')">发 布</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="detailVisible" custom-class="preview_dialog">
                <div>
                    <qrCode :rowId="rowData == null ? '' : rowData.id" class="big_code"></qrCode>
                </div>
                <div class="img_top"><el-link @click="seeDetail()" :underline="false">点击查看</el-link></div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="detailVisible = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="checkVisible" custom-class="news_dialog" :close-on-click-modal="false" title="资讯详情">
                <el-steps :space="200" :active="stepActive" finish-status="finish" align-center>
                    <el-step title="创建" icon="el-icon-circle-check"></el-step>
                    <el-step title="校对" icon="el-icon-circle-check"></el-step>
                    <el-step title="审核" icon="el-icon-circle-check"></el-step>
                    <el-step title="发布"></el-step>
                </el-steps>
                <div class="check_content">
                    <div class="time_status">
                        <div class="show_text">
                            <div>
                                <h1>{{ getForm == null ? '' : getForm.subject }}</h1>
                                <h3>{{ getForm == null ? '' : getForm.short_subject }}</h3>
                                <div class="bottom_txt">
                                    <div class="create_date">{{ getForm == null ? '' : getForm.created_at || '-' }}</div>
                                    <div class="right_label">
                                        <el-tag type="danger" v-if="getForm != null && getForm.status == 0">未校验</el-tag>
                                        <el-tag key="未审核" type="warning"
                                            v-else-if="getForm != null && getForm.status == 1">未审核</el-tag>
                                        <el-tag key="未发布" type="info"
                                            v-else-if="getForm != null && getForm.status == 2 && getForm.publish == 0">未发布</el-tag>
                                        <el-tag key="已发布" type="success" v-else>已发布</el-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_right">
                            <div>
                                <el-radio-group v-model="pass">
                                    <el-radio label="1">通过</el-radio>
                                    <el-radio label="2">不通过</el-radio>
                                </el-radio-group>
                            </div>
                            <div>
                                <div class="text_word">发布意见:</div>
                                <div><el-input type="textarea" v-model="idea"></el-input></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="infoCancle()">取 消</el-button>
                    <el-button type="primary" @click="informateSubmit()">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <infoDetailModal ref="infoDetailRef" :getForm="getForm" :logData="logData"></infoDetailModal>
        </div>
        <div>
            <addviceForm ref="addviceFormRef"></addviceForm>
        </div>
    </div>
</template>  
<script>
import {
    organizeList, publishList, publishAdd, publishDel, queryInfo, organizationsInfo,
    publishEdit, publishClose, publishCheck, examineNews, publishNews, cacheOrganize,
    getCacheData
} from "../../../../../api/enterprise";
import standFormHead from "../../technicalReview/components/standFormHead.vue"
import uploadFile from "../../technicalReview/components/uploadFile.vue"
import richTextEdit from "../../technicalReview/components/richTextEdit.vue"
import qrCode from "../../technicalReview/components/qrBarCode.vue"
import infoDetailModal from "../../technicalReview/components/InfoDetails.vue"
import standardFile from "../../technicalReview/components/standardFile.vue"
import AddviceForm from "../../technicalReview/components/addviceForm.vue";
export default {
    name: 'publicannounce',
    components: { standFormHead, uploadFile, richTextEdit, qrCode, infoDetailModal, standardFile, AddviceForm },
    filters: {
        formatDate: function (timestamp, fmt) {
            if (fmt == undefined || fmt == "" || fmt == null) {
                fmt = "yyyy-MM-dd ";
            }
            if (!timestamp) {
                return "";
            }
            let date = new Date(timestamp);
            // 第一步先替换年份 因为年份跟其他不一样是四位
            // let pat =
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, date.getFullYear());
            }
            // 第二步匹配其他，然后替换
            let obj = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
            };
            for (let key in obj) {
                let pat = `(${key})`;
                if (new RegExp(pat).test(fmt)) {
                    let str = obj[key] + "";
                    // RegExp.$1  MM dd hh mm ss贪婪匹配
                    fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
                }
            }
            return fmt;
        },
    },
    // inject: [],
    provide() {
        return {

        }
    },
    data() {
        return {
            qrcodeUrl: "../../../../../assets/QRCode.png",
            text: '',
            qrcode: null,
            submenu: "publicity",
            // value: "文字",
            size: 300,
            markcouter: "",
            standardData: [],
            editDialogVisible: false,
            editForm: {
                subject: "",
                short_subject: "",
                category_id: "",
                tig: "",
                organization_id: '',
                bumen: '',
                status: '',
                publish: '',
                wxdate: '',
                photo: '',
                content: '',
                files: [], //上传文件
                updated_at: '',
                created_at: '',
                check: '',
                url: '',
                stick: "1",
                sheng: false
            },
            filterTypeOptions: [
                {
                    label: '地方标准发布',
                    value: 11,
                },
                {
                    label: '地方标准发布(意见征求)',
                    value: 15,
                },
            ],
            contentTypeOptions: [
                {
                    label: '最新新闻',
                    value: 1,
                },
                {
                    label: '科技动态',
                    value: 2,
                },
                {
                    label: '最新政策',
                    value: 3,
                },
                {
                    label: '国内新闻',
                    value: 4,
                },
                {
                    label: '国际科技',
                    value: 5,
                },
                {
                    label: '国内科技',
                    value: 6,
                },
            ],
            organizationOptions: [],
            deptOptions: [
                {
                    label: '综合部',
                    value: 1,
                },
                {
                    label: '标准化部',
                    value: 2,
                },
                {
                    label: '绿色建筑部',
                    value: 3,
                },
                {
                    label: '建筑产业部',
                    value: 4,
                },
                {
                    label: '评价标识部',
                    value: 5,
                },
            ],
            fileType: 'image',
            page: 1,
            limit: 15,
            totalCount: 0,
            totalPage: 1,
            token: "",
            richcontent: "",
            detailVisible: false,
            rowData: null,
            checkVisible: false,
            auditSteps: 0,
            getForm: null,
            pass: "1",
            idea: "",
            logData: [],
            isAdd: false,
            stepActive: 1,
            editTitle: '',
            typeName:''
        }
    },
    props: {
        newsType: {
            type: String
        }
    },
    created() {
        // console.log('打印文件类型', this.fileType)
    },
    mounted() {
        this.token = this.$store.state.token
            // this.getPublishData();
            this.enterpriseData();
    },
    methods: {
        //搜索
        fuzzySearch(formdata) {
            this.page = 1
            this.getPublishData(formdata)
        },
        clearQuery() {
            this.page = 1
            this.getPublishData()
        },
        getPublishData(formdata) {
            // console.log(formdata, '初次请求')
            if(this.newsType === '11'){
                this.typeName = '地方标准发布'
            }else{
                this.typeName = '地方标准发布(意见征求)'
            }
            let data = {
                token: this.$store.state.token,
                page: this.page,
                limit: this.limit,
                type: formdata != undefined && formdata.type != '' ? formdata.type : this.newsType,
                name: formdata === undefined ? undefined : formdata.name,
                tig: formdata != undefined && formdata.tig != '' ? formdata.tig : undefined,
                status: formdata != undefined && formdata.status != '' ? formdata.status : undefined,
                publish: formdata != undefined && formdata.publish != '' ? formdata.publish : undefined,
            }
            publishList(data).then(rec => {
                // debugger
                console.log('公示公告lists', rec)
                this.standardData = rec.data
                this.page = rec.page //当前页
                this.totalCount = rec.totalCount
                this.totalPage = rec.totalPage
                this.limit = rec.limit
            })
        },
        //获取文件的数据
        getFileData(obj) {
            console.log(obj, '打印文件对象')
            this.editForm.photo = obj.photo
            this.editForm.files = obj.files
        },
        //富文本双向绑定
        contentChange(content) {
            // console.log('接收文本', content)
            this.richcontent = content
            this.editForm.content = content
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return 'warning-row'
            }
        },
        //切换子菜单
        subMenuHandle() {
            // standard_word
            if (this.submenu == 'publicity') {
                //标准发布
                this.page = 1
                this.getPublishData()
            } else {
                // debugger
                // this.$refs.standDocument.page = 1
                // this.$refs.standDocument.queryStandard()
            }
        },
        //新增
        addFrom(type) {
            // 清空本地存储
            localStorage.clear();
            if (type === '0') {
                //新增表单
                this.editForm.sheng = false
                this.editForm.id = ''
                this.isAdd = true
                const casheId = localStorage.getItem('casheId');
                console.log(casheId, '缓存的id打印')
                if (casheId) {
                    getCacheData({ id: casheId }).then(rec => {
                        // debugger
                        // console.log('查询暂存的数据', rec)
                        this.editForm = rec.data[0]
                        this.editForm.stick = String(rec.data[0].stick)
                        this.editForm.organization_id = ''
                        this.$refs.richTextRef.content = rec.data[0].content;
                    })
                }
                this.editDialogVisible = true
            } else {
                //新建公开意见
                this.$refs.addviceFormRef.adviceDialogVisible = true
                this.$refs.addviceFormRef.queryStandards()
            }

        },
        //查询企业
        enterpriseData() {
            let data = {
                page: 1,
                limit: 50,
                token: this.$store.state.token//获取cookie字符串
            }
            organizeList(data).then(rec => {
                // debugger
                this.organizationOptions = rec.data
            })
        },
        handleSizeChange(page) {
            this.limit = page;
            this.getPublishData();
        },
        handleCurrentChange(page) {
            this.page = page;
            this.getPublishData();
        },
        //打开详情
        handleUpdate(row) {
            this.editDialogVisible = true
            this.isAdd = false
            queryInfo({ token: this.token, id: row.id }).then(rec => {
                // debugger
                this.editForm = { ...rec.data }
                // console.log(rec, '打印详情返回的内容--->')
                this.editForm.stick = String(rec.data.stick)
                this.editForm.organization_id = rec.data.id
                if (rec.data.sheng == 1) {
                    this.editForm.sheng = false
                } else if (rec.data.sheng == 2) {
                    this.editForm.sheng = true
                }
                this.$refs.richTextRef.content = rec.data.content
                //图片的回显
                this.$refs.uploadimg.fileList = []
                if (rec.data.photo != '') {
                    this.$refs.uploadimg.fileList.push({ url: rec.data.photo })
                } else {
                    return
                }
            })
            this.organizeData(row)
        },
        //标题查看资讯详情
        infoEvent(row) {
            // console.log(this.$refs)
            this.$refs.infoDetailRef.infoDetailVisible = true
            this.infoDetailData(row)
        },
        //查询资讯详情
        infoDetailData(row) {
            let params = {
                id: row.id,
                token: this.token
            }
            queryInfo(params).then(rec => {
                // debugger
                console.log('打印信息', rec)
                this.getForm = rec.data
                this.logData = rec.data.log
            })
        },
        //审核
        handleCheck(row) {
            // console.log(row, '打印行信息')
            this.getForm = row
            this.stepActive = 1
            if (row.publish == null && row.status == 0) {
                //未校验未发布
                this.stepActive = 1
            } else if (row.status == 1) {
                //有校验未审核
                this.stepActive = 2
            } else if (row.publish == 0 && row.status == 2) {
                //已审核已发布
                this.stepActive = 3
            } else if (row.publish == 1 && row.status == 2) {
                //已审核已发布
                this.stepActive = 4
            }
            this.checkVisible = true
        },
        organizeData(row) {
            organizationsInfo({ token: this.token, id: row.id }).then(rec => {
                // console.log('查询组织详情的结果', rec.data)
                const obj = {
                    name: rec.data.name,
                    id: rec.data.id,
                };
                // console.log('打印要添加的对象内容', obj)
                this.organizationOptions.push(obj)
            })
        },
        handleDelete(row) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                publishDel({ token: this.token, id: row.id, source: 4 }).then(rec => {
                    // debugger
                    console.log('返回的结果', rec)
                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.getPublishData();
                        this.editDialogVisible = false
                    }
                })
            }).catch(() => {
                // this.$message({
                //   type: 'info',
                //   message: '已取消删除'
                // });
            });
        },
        //暂存表单
        saveEdit(formName) {
            let randomId = Math.floor(Math.random() * 1000)
            console.log(randomId, '打印随机数字')
            this.editForm.content = this.richcontent;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //暂存
                    let data = {
                        ...this.editForm,
                        sheng: this.editForm.sheng == false ? 1 : 2,
                        stick: Number(this.editForm.stick),
                        id: randomId
                    }
                    localStorage.setItem('casheId', randomId);
                    cacheOrganize(data).then(rec => {
                        // debugger
                        console.log('返回暂存的结果', rec)
                        if (rec.code == 200) {
                            this.$message({
                                type: "success",
                                message: rec.message
                            })
                            this.getPublishData();
                            this.editDialogVisible = false
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //提交表单
        submitEdit(formName) {
            console.log(this.editForm.id, '打印表单数据')
            // console.log(this.richcontent)
            this.editForm.token = this.token;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editForm.token = this.token;
                    this.editForm.content = this.richcontent;
                    let params = {
                        ...this.editForm,
                        category_id: this.newsType === '11'?'11':'15',
                        sheng: this.editForm.sheng == false ? 1 : 2,
                        stick: Number(this.editForm.stick)
                    }
                    // console.log(params, '打印提交的表单数据')
                    if (this.editForm.id === undefined || this.editForm.id === '') {
                        //新增
                        publishAdd(params).then(rec => {
                            // debugger
                            console.log('返回的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.getPublishData();
                                this.editDialogVisible = false
                            }
                        })
                    } else if (this.editForm.id != undefined || this.editForm.id != '') {
                        let data = {
                            ...this.editForm,
                            sheng: this.editForm.sheng == false ? 1 : 2,
                            category_id: this.newsType === '11'?'11':'15'
                        }
                        publishEdit(data).then(rec => {
                            // debugger
                            console.log('修改的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.getPublishData();
                                this.editDialogVisible = false
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //撤回
        withDraw(row) {
            this.$confirm('是否确认撤回?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    token: this.token,
                    id: row.id
                }
                publishClose(params).then(rec => {
                    // debugger
                    console.log('撤回的结果', rec)
                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.getPublishData();
                    }
                })
            }).catch(() => {

            });
        },
        //取消
        cancleEdit() {
            this.editDialogVisible = false
            this.$refs.richTextRef.content = ''
            this.$refs.editForm.resetFields();
        },
        jumpArticle(row) {
            this.detailVisible = true
            this.rowData = row
        },
        seeDetail() {
            window.open("https://gbservice.cn/#/detailbk?id=" + this.rowData.id);
        },
        //资讯取消
        infoCancle() {
            this.checkVisible = false
        },
        //资讯详情提交
        informateSubmit() {
            const { id, status } = this.getForm;
            const submitForm = {
                id: this.getForm.id,
                pass: this.pass,
                idea: this.idea,
                token: this.token,
                source: 4
            };
            if (!status) {
                publishCheck(submitForm).then((res) => {
                    // console.log('打印返回786的值', res)
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                });
                return;
            }
            if (status == 1) {
                examineNews(submitForm).then((res) => {
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                    // console.log('打印返回345的值', res)
                });
                return;
            }
            const publishForm = {
                id: this.getForm.id,
                token: this.token,
                source: 4
            };
            publishNews(publishForm).then((res) => {
                if (res.code == 200) {
                    this.getPublishData();
                    this.idea = ''
                    this.checkVisible = false
                    // this.pass = '1'
                }
                // console.log('打印返回123的值', res)
            });
        }
    },
}
</script>
<style scoped>
/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409EFF;
    border-color: #409EFF;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2D2D2D;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

/deep/ .el-link.el-link--primary {
    padding-right: 30px !important;
}

.pitable {
    /*height: 60vh;*/
}

.litop {
    margin: 20px 24px;
}

.litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082E2;
}

.linkto>span:hover {
    color: #84b5ec;
}

/* 新改 */
/* .zhidingsearch {
    background-color: #ffffff;
    padding: 1px 15px;
    margin: 20px 0 10px 0;
} */

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
}

.form_line {
    display: flex;
}

.form_line .el-form-item {
    width: 50%;
    margin: 0 16px 24px 0;
}

.form_line .el-form-item .el-input,
.el-select {
    width: 80%;
}

.plus_add {
    margin: 20px 0 20px 0;
}

.agree {
    margin-left: 18px;
}

/deep/ .preview_dialog {
    width: 500px;
}

.preview_dialog .img_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .preview_dialog .prev_img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
} */

/deep/ .preview_dialog .el-link--inner {
    color: #0099fc;
}

/* .qrcode_img {
    vertical-align: middle;
    width: 50px;
    height: 50px;
} */

/deep/ .news_dialog {
    width: 70%;
}

/deep/ .news_dialog .el-dialog__header {
    text-align: center;
}

/deep/ .el-step__head.is-finish,
/deep/ .el-step__head.is-process {
    width: 320px;
}

/deep/ .big_code img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
    margin-bottom: 11px;
}
</style>
<style scoped lang="scss">
.time_status {
    display: flex;
    justify-content: space-between;

    .show_text {
        width: 60%;
        display: flex;
        justify-content: space-between;

        .bottom_txt {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .create_date {
                padding-top: 6px;
            }
        }
    }

    .form_right {
        width: 50%;
        margin-top: 26px;
        margin-left: 13%;

        .text_word {
            margin: 16px 0 16px 0;
        }
    }
}

.double_add {
    display: flex;

    .suggest {
        margin-left: 18px;
    }
}

.subtext {
    color: #0099fc;
    cursor: pointer;
}
</style>